exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-calendar-js": () => import("./../../../src/templates/calendar.js" /* webpackChunkName: "component---src-templates-calendar-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-education-js": () => import("./../../../src/templates/education.js" /* webpackChunkName: "component---src-templates-education-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-give-js": () => import("./../../../src/templates/give.js" /* webpackChunkName: "component---src-templates-give-js" */),
  "component---src-templates-history-js": () => import("./../../../src/templates/history.js" /* webpackChunkName: "component---src-templates-history-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-privacy-pages-js": () => import("./../../../src/templates/privacy-pages.js" /* webpackChunkName: "component---src-templates-privacy-pages-js" */),
  "component---src-templates-volunteer-js": () => import("./../../../src/templates/volunteer.js" /* webpackChunkName: "component---src-templates-volunteer-js" */)
}

